<template>
<div class="dp-header">
    <div class="container">
        <div class="row" v-if="storeProfileSearch">
            <div class="col-md-8 dph-info">

                <a :href="storeProfileSearch.domain_link" target="_blank">
                    <img v-if="storeProfileSearch.image" :src="$base_url + storeProfileSearch.image" class="profile-img" alt />
                    <img v-else class="profile-img" src="../../assets/placeholder.png" alt />
                </a>

                <div>
                    <h4>{{storeProfileSearch.name}}</h4>
                    <p>{{storeProfileSearch.summary}}<a href="#description" style="border: none;">Read more... </a></p>
                </div>
            </div>
            <div class="col-md-4 dph-reviews">
                <!-- <p>
            <span>
              9,2 &nbsp;
              <em>/10</em>
            </span>
            21 reviews
          </p> -->
                <p class="dph-rec" v-if="storeSearch">
                    <!-- <i class="ti-cut"></i> -->
                    <span>{{storeSearch.length}}</span> Offers
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['storeProfileSearch', 'storeSearch'],
    data() {
        return {

        }
    },
    methods: {
        update(data) {
            return data.slice(0, 620) + ''
        }
    }
}
</script>
