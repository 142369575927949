<template>
<!--    <div class="site-wrapper animsition" data-animsition-in="fade-in" data-animsition-out="fade-out">-->
<div>
    <!--start For redirection -->
    <a :href="currentRedirectionURL" style="display: none;" rel="noreferrer" target="_blank" id="currentRedirectionURL">link</a>
    <a :href="currentRedirectionURL" style="display: none;" target="_blank" id="currentRedirectionURL_ref">link</a>
    <!--end For redirection -->

    <!-- Navigation Bar-->
    <navbar></navbar>

    <!-- Navigation ends -->
    <section class="results">

        <dp-header :storeProfileSearch="storeProfileSearch" :storeSearch="storeSearch"></dp-header>

        <div class="container">
            <div class="row">
                <!--/col -->
                <div class="col-sm-8 col-sm-push-4 ">
                    <!--/widget -->
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div role="tabpanel" class="tab-pane single-coupon active" id="popular">
                            <single-coupon 
                            v-for="(singlecoupon, i) in displayedSearchs" :key="i" 
                            :singlecoupon="singlecoupon" 
                            :storeProfileSearch="storeProfileSearch" 
                            :automaticCouponModelTriggerId="automaticCouponModelTriggerId" 
                            @click-get-code="clickGetCode" 
                            :currentRedirectionMode="currentRedirectionMode"
                            :currentRedirectionURL="currentRedirectionURL"
                            />
                            <div id="description"></div>
                            <!--/COUPON-->
                        </div>
                        <!-- / tabpanel -->
                    </div>
                    <ul class="pagination pagination-lg m-t-0">
                        <li>
                            <a href="javascript://" v-if="page != 1" @click="page--">
                                <i class="ti-arrow-left"></i>
                            </a>
                        </li>
                        <!-- :class="{ 'active':(page == i) }" -->

                        <li v-for="(pageNumber, i) in pages.slice(page - 1, page + 2)" :key="i" @click="page = pageNumber" :class="{ active: page == pageNumber }">
                            <a href="javascript://">{{ pageNumber }}</a>
                        </li>
                        <li>
                            <a href="javascript://" @click="page++" v-if="page < pages.length">
                                <i class="ti-arrow-right"></i>
                            </a>
                        </li>
                    </ul>

                    <!-- end: Tab content -->
                    <!-- Poplura stores -->
                    <div class="widget" v-if="storeProfileSearch">
                        <!-- /widget heading -->
                        <div class="widget-heading">
                            <h3 class="widget-title text-dark">About {{updateMacros(storeProfileSearch.name)}}</h3>
                            <div class="widget-widgets">
                                <a href="#">
                                    <!--                                        View More Stores-->
                                    <!--                                        <span class="ti-angle-right"></span>-->
                                </a>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="widget-body">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                    <div v-html="updateMacros(storeProfileSearch.description)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end:Popular stores widget -->

                </div>
                <div class="col-sm-4 col-sm-pull-8 ">
                    <div class="widget trending-coupons">
                        <!-- /widget heading -->
                        <div class="widget-heading">
                            <h3 class="widget-title text-dark">Trending Coupons</h3>
                            <div class="clearfix"></div>
                        </div>
                        <div class="widget-body" v-if="randomCouponData">
                            <random v-for="(card, i) in randomCouponData.slice(0,10)" :key="i" :card="card" />
                        </div>
                        <!-- // widget body -->
                    </div>
                    <div class="widget" v-if="topStores">
                        <!-- /widget heading -->
                        <div class="widget-heading">
                            <h3 class="widget-title text-dark">Top Stores</h3>
                            <div class="clearfix"></div>
                        </div>
                        <div class="widget-body">
                            <div class="row">
                                <display-stores-horizontally v-for="(top, i) in topStores.slice(0,10)" :key="i" :store="top"></display-stores-horizontally>
                                <!-- /thumb -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Footer -->
    <foot></foot>
    <!-- start modal -->
    <!-- Large modal -->
    <!-- Large modal -->

    <coupon-offer-modal :offerCoupon="displayedCouponOfferData" :storeCouponOffer="eachCouponOffer" :storeImage="storeImage">
    </coupon-offer-modal>

    <!-- end: Modall -->
    <!-- end: Modall -->
</div>
<!-- //wrapper -->
</template>

<script>
import {
    mapState
} from "vuex";
import DpHeader from "@/components/store/DpHeader";
import SingleCoupon from "@/components/store/SingleCoupon";
import {
    commonCopyMixin
} from "@/components/Mixins/commonCopyMixin";
import Random from "@/components/contact/Random";
import api2 from "../utils/api2";
import {detectMobile} from "../utils/functions";
import { exit } from "process";

export default {
    name: "Store",
    mixins: [commonCopyMixin],
    components: {
        DisplayStoresHorizontally: () => import( /* webpackPrefetch: true */ '../components/contact/DisplayStoresHorizontally'),
        Foot: () => import( /* webpackPrefetch: true */ '../components/core/Footer'),
        TopStore: () => import( /* webpackPrefetch: true */ '../components/home/TopStore'),
        Navbar: () => import( /* webpackPrefetch: true */ '../components/core/Navbar'),
        CouponOfferModal: () => import( /* webpackPrefetch: true */ '../components/common/CouponOfferModal'),
        DpHeader,
        SingleCoupon,
        Random,
    },
    data() {
        return {
            couponApplied: false,
            textToCopy: null,
            page: 1,
            perPage: 10,
            pages: [],
            currentPage: 1,
            eachCouponOffer: [],
            storeImage: null,
            displayedCouponOfferData: {},
            window: {
                width: 0,
                height: 0
            },
            automaticCouponModelTriggerId: "",
            isMobile: detectMobile(),
            currentRedirectionURL:'',
            currentRedirectionMode: "",
        };
    },
    async created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        this.$store.state.showLoader = true;
        this.$store.state.redictCoupon = 0;
        await this.indexMethod()
        this.$store.state.showLoader = false;
        await this.$store.dispatch("coupon/fetchPopularCoupon").then((res) => {});
        await this.$store.dispatch("coupon/fetchTopStores").then((res) => {});
        this.$store.dispatch('coupon/googleVerification')
        this.checkZeroRedirectMode();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        storeSearch() {
            this.setPages();
        },
        '$route'() {
            this.indexMethod()
        }
    },

    computed: {
        ...mapState({
            offerCoupon: (state) =>
                state.coupon.offerCoupon ? state.coupon.offerCoupon : [],
            storeSearch: (state) =>
                state.coupon.storeSearch ? state.coupon.storeSearch : [],
            storeProfileSearch: (state) =>
                state.coupon.storeProfileSearch ? state.coupon.storeProfileSearch : [],
            randomCoupon: (state) =>
                state.coupon.popularCoupon ? state.coupon.popularCoupon : [],
            topStores: (state) =>
                state.coupon.topStores ? state.coupon.topStores : [],
        }),
        randomCouponData() {
            let coupons = this.$store.state.coupon.popularCoupon;
            return coupons?coupons.filter(val => val.is_visible === true):[]
        },
        storeSearchData() {
            let coupons = this.$store.state.coupon.storeSearch;
            return coupons.filter(val => val.is_visible === true)
        },
        displayedSearchs() {
            return this.paginate(this.storeSearchData);
        },
        redrectionConditions(){
            if(this.storeProfileSearch.hasOwnProperty('redirection_condition')
            && this.storeProfileSearch.redirection_condition.length>0){
                return this.storeProfileSearch.redirection_condition[0]
            }
            return {}
        }
    },
    methods: {
        async clickGetCode(value, profileSearch) {

            console.log('coupon click data')
            console.log(value)

            if (value.hasOwnProperty('rtl') && value.rtl == 1) {
                this.$store.state.rtl_mode = 1
            } else {
                this.$store.state.rtl_mode = 0
            }

            if (value.coupon_code) {
                this.textToCopy = value.coupon_code;
            } else {
                this.textToCopy = "ABC2020";
            }

            //redirection condition------ Mobile
            if(this.isMobile == true && this.redrectionConditions.hasOwnProperty('mobile_redirection_type')
            && (this.redrectionConditions.mobile_redirection_type == "zero-redirect-with-refer" 
            || this.redrectionConditions.mobile_redirection_type == "zero-redirect-without-refer")
            ){
                if(this.redrectionConditions.mobile_redirection_type == "zero-redirect-without-refer"){
                    this.currentRedirectionMode = 'zrwor';
                    return window.open(this.redrectionConditions.mobile_base_url, '_blank','noreferrer');
                }else{
                    this.currentRedirectionMode = 'zrwr';
                  return window.open(this.redrectionConditions.mobile_base_url, '_blank');
                }
            }

            //redrection condition------ Desktop
            if(this.isMobile == false && this.redrectionConditions.hasOwnProperty('desktop_redirection_type')
            && (this.redrectionConditions.desktop_redirection_type == "zero-redirect-with-refer"
            || this.redrectionConditions.desktop_redirection_type == "zero-redirect-without-refer")
            ){
                this.currentRedirectionURL = this.redrectionConditions.desktop_base_url;

               if(this.redrectionConditions.desktop_redirection_type == "zero-redirect-without-refer"){
                this.currentRedirectionMode = 'zrwor';
                return setTimeout(function () {
                    document.getElementById('currentRedirectionURL').click();
                }, 1000);
               }else{
                this.currentRedirectionMode = 'zrwr';
                return setTimeout(function () {
                    document.getElementById('currentRedirectionURL_ref').click();
                }, 1000);
               }
            }


            // let url = "https://api.bisharafak.com/v2/fetch/couponurl"
            // await api2.post(url, {
            //     coupon_id: value._id,
            //     resolution: this.window.width + 'x' + this.window.height,
            //     lpURL: document.URL,
            // }).then((response) => {
            //     if (response.data) {
            //         let data = response.data
            //         if (data.success = true) {
            //             value.link = data.link
            //         }
            //     }
            // })
            value.link = value.finalLink

            this.eachCouponOffer = value;
            this.storeImage = profileSearch;
            this.$store.state.redictCoupon = 0;
            this.$store.state.redirectCounter = 5;
            this.$store.state.coupon_link = value.finalLink ?? null;
            this.commonCopy(value.finalLink);
        },
        async onLoadStoreId(query, params, slug) {
            let payload = query;

            if (slug) {
                payload.slug = slug;
            }
            if (params) {
                payload = {
                    ...payload,
                    ...params,
                    ...this.window
                };
            }
            await this.$store
                .dispatch("coupon/fetchCouponForStore", payload)
                .then((res) => {
                    if (res.data) {
                        // res.data = res.data.data;
                        //res.data.result = "aborior";
                        if (res.data.result == "aborior") {} else {
                            if (payload.hasOwnProperty('rtl') && payload.rtl == 1) {
                                this.$store.state.rtl_mode = 1
                            } else {
                                this.$store.state.rtl_mode = 0
                            }
                            if (res.data.hasOwnProperty('rtl') && (res.data.rtl == 1 || res.data.rtl == true)) {
                                this.$store.state.rtl_mode = 1
                            }

                            let data = {};
                            data.description = this.storeProfileSearch.summary
                            this.displayedCouponOfferData = {
                                ...data,
                                ...res.data
                            }

                            this.$store.state.redictCoupon = 1;
                            this.textToCopy = res.data.code;
                            this.$store.state.coupon_link = res.data.navigate;
                            this.$store.state.couponCopyed = 1;
                            this.copyToClipboard(this.textToCopy);
                            // alert(this.$store.state.coupon_link)
                            this.commonCopy(res.data.navigate, true);
                        }
                    } else {
                        $("#couponOfferModal").modal("hide");
                        this.$store.state.rtl_mode = 0
                    }
                });
        },
        async onLoadStoreBySearch(params, store_id, slug) {
            let payload = {
                resolution: this.window.width + 'x' + this.window.height,
            }

            if (store_id) {
                payload.store_id = store_id;
            }
            if (slug) {
                payload.slug = slug;
            }

            if (params) {
                payload = {
                    ...payload,
                    ...params
                };
            }
            await this.$store
                .dispatch('coupon/fetchSearchInStore', payload)
                .then((res) => {});
        },
        setPages() {
            let numberOfPages = Math.ceil(this.storeSearch.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        paginate(storeSearch) {
            this.currentPage = this.page;
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return storeSearch.slice(from, to);
        },
        async indexMethod() {

            if (this.$route.query.couponId) {
                this.automaticCouponModelTriggerId = this.$route.query.couponId;
            }

            if (this.$route.query.search) {
                await this.onLoadStoreBySearch(this.$route.query.search);
            }

            if (this.$route.params.code || this.$route.params.slug) {
                let params = 0;
                if (this.$route.query.aff_sub4) {
                    params = this.$route.query;
                }
                await this.onLoadStoreBySearch(params, this.$route.params.code, this.$route.params.slug);
            }

            this.$store.state.showLoader = false;
            if (this.$route.query.unique_id && this.$route.query.shopping_id) {
                this.storeImage = this.storeProfileSearch.image ?? null
                await this.onLoadStoreId(this.$route.query, this.$route.params);
            }

            //scroll the page to coupon id
            if (this.$route.params.couponId) {
                this.scrollMeTo(this.$route.params.couponId)
            }
        },
        scrollMeTo(refName) {
            const el = document.getElementById(refName);
            el.scrollIntoView({
                behavior: "smooth"
            });
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        updateMacros(description = '') {
            const d = new Date();
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            let year = d.getFullYear();
            let month = monthNames[d.getMonth()];
            let temp = description;

            temp = temp.replaceAll("{year}", year);
            temp = temp.replaceAll("{Year}", year);
            temp = temp.replaceAll("{YEAR}", year);

            temp = temp.replaceAll("{month}", month);
            temp = temp.replaceAll("{Month}", month);
            temp = temp.replaceAll("{MONTH}", month);

            return temp;
        },
        checkZeroRedirectMode(){
                //redirection condition------ Mobile
           if(this.isMobile == true && this.redrectionConditions.hasOwnProperty('mobile_redirection_type')
            && (this.redrectionConditions.mobile_redirection_type == "zero-redirect-with-refer" 
            || this.redrectionConditions.mobile_redirection_type == "zero-redirect-without-refer")
            ){
                this.currentRedirectionURL = this.redrectionConditions.mobile_base_url;

                if(this.redrectionConditions.mobile_redirection_type == "zero-redirect-without-refer"){
                    this.currentRedirectionMode = 'zrwor';
                }else{
                    this.currentRedirectionMode = 'zrwr';
                }
            }

            //redrection condition------ Desktop
            if(this.isMobile == false && this.redrectionConditions.hasOwnProperty('desktop_redirection_type')
            && (this.redrectionConditions.desktop_redirection_type == "zero-redirect-with-refer"
            || this.redrectionConditions.desktop_redirection_type == "zero-redirect-without-refer")
            ){
                this.currentRedirectionURL = this.redrectionConditions.desktop_base_url;

               if(this.redrectionConditions.desktop_redirection_type == "zero-redirect-without-refer"){
                this.currentRedirectionMode = 'zrwor';
               }else{
                this.currentRedirectionMode = 'zrwr';
               }
            }
        }
    },
};
</script>

<style scoped>
</style>
